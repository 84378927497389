import axios from 'axios'

export default {
    getSellersWithSales: (month) => axios.post('graphs/get-seller-with-sales', {month: month}),
    getCustomersReached: (month) => axios.post('graphs/get-customers-reached', {month: month}),
    getMonthSales: (month) => axios.post('graphs/get-month-sales', {month: month}),
    getAvailableInvestment: (month) => axios.post('graphs/get-available-investment', {month: month}),
    getCedisPerformance: (month) => axios.post('graphs/get-cedis-performance', {month: month}),
    getMonthsPerformance: (month) => axios.post('graphs/get-months-performance', {month: month}),
    getProjectionSales: (month) => axios.post('graphs/get-projection-sales', {month: month}),

    getSalesVsTarget: (month) => axios.post('graphs/get-sales-vs-target', {month: month}),
    getSalesByCedis: (month) => axios.post('graphs/get-sales-by-cedis', {month: month}),
    getCustomerDistribution: (month) => axios.post('graphs/get-customer-distribution', {month: month})
}