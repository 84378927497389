<template>
    <div>
        <div class="text-center">
            <div style="font-size: 16px;"><strong>{{title}}</strong></div>
        </div>        
        <div style="width: 100%; height: 700px;" id="chartdiv">                            
        </div>
    </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5index from "@amcharts/amcharts5/index";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
// import ws from '@/services/graphs';

import ws from '../../services/dashboards';
import store from '../../store'

export default {
    name: 'DoubleBarVerticalGraph',
    props: {
        permissions: {
            type: Object,
            default() {
                return {}
            }
        },
        title: {
            type: String,
            default() {
                return "Desempeño por CEDIS"
            }
        },              
    },
    components : {
	},
    data: function () {
		return {
            items: {},
            root: "",
            chart: "",
            xAxis:"",
            yAxis:"",
            wheelX: false,
            wheelY: false,
            data: "",
            legend:"",
        }
    },
    computed: {
        
    },
    mounted: async function(){    
        this.loading();

        let response = await ws.getSalesByCedis(this.month);

        if(response.type == "success"){
            this.data = response.data;

            this.loadGraph(this.data);
        }

        this.loaded();
    },
    methods: {
        makeSeries(name, fieldName, root,data) {
            let series = this.chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: this.xAxis,
                yAxis: this.yAxis,
                valueYField: fieldName,
                categoryXField: "cedis"
            }));

            series.columns.template.setAll({
                tooltipText: "{name}: {valueY}",
                width: am5.percent(90),
                tooltipY: 0,
                strokeOpacity: 0
            });

            series.data.setAll(data);
            series.appear();

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                locationY: 0,
                sprite: am5.Label.new(root, {
                    text: "{valueY}",
                    fill: root.interfaceColors.get("alternativeText"),
                    centerY: 0,
                    centerX: am5.p50,
                    populateText: true
                })
                });
            });

            this.legend.data.push(series);
        },
        loadGraph: async function(data){
            if (this.root) {
                this.root.dispose();
            }

            this.root = am5.Root.new("chartdiv");

            this.root.setThemes([
                am5themes_Animated.new(this.root)
            ]);
            this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
                panX: false,
                panY: false,
                wheelX: false,
                wheelY: false,
                paddingLeft:0,
                pinchZoomX: true,
                layout: this.root.verticalLayout
            }));

            this.legend = this.chart.children.push(am5.Legend.new(this.root, {
                centerX: am5.p50,
                x: am5.p50
            }))

            let xRenderer = am5xy.AxisRendererX.new(this.root, {
                cellStartLocation: 0.1,
                minGridDistance: 5,                 
                minorGridEnabled: true
            })

            xRenderer.labels.template.setAll({
                rotation: -90,
                centerY: am5.p50,
                centerX: am5.p100,
                paddingRight: 15,
                fontSize: "11px"
            });

            this.xAxis = this.chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
                categoryField: "cedis",
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(this.root, {})
            }));

            xRenderer.grid.template.setAll({
                location: 1
            })

            this.xAxis.data.setAll(data);

            this.yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
                renderer: am5xy.AxisRendererY.new(this.root, {
                    strokeOpacity: 0.1
                })
            }));

            // let varianzaAxisRenderer = am5xy.AxisRendererY.new(this.root, {});
            // varianzaAxisRenderer.grid.template.set("forceHidden", true);
            // let varianzaAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
            //     renderer: varianzaAxisRenderer,
            //     forceHidden: false
            // }));

            this.makeSeries("2023","last_year",this.root,data);
            this.makeSeries("2024","this_year",this.root,data);

            //Varianza
            // let varianzaSeries = this.chart.series.push(am5xy.LineSeries.new(this.root, {
            //     xAxis: this.xAxis,
            //     yAxis: varianzaAxis,
            //     valueYField: "varianza",
            //     valueXField: "service",
            //     tooltip:am5.Tooltip.new(this.root, {
            //         labelText:"Varianza: {valueY}%"
            //     })  
            // }));

            // varianzaSeries.strokes.template.setAll({ strokeWidth: 2 });

            // varianzaSeries.bullets.push(function() {
            //     let graphics = am5.Circle.new(this.root, {
            //         strokeWidth: 2,
            //         radius: 5,
            //         stroke: varianzaSeries.get("stroke"),
            //         fill: this.root.interfaceColors.get("background"),
            //     });

            //     graphics.adapters.add("radius", function(radius, target) {
            //         return target.dataItem.dataContext.townSize;
            //     })

            //     return am5.Bullet.new(root, {
            //         sprite: graphics
            //     });
            // });

            // varianzaSeries.data.setAll(data);
            this.xAxis.data.setAll(data);



            // this.legend = this.chart.children.push(am5.Legend.new(this.root, {
            //     centerX: am5.p50,
            //     x: am5.p50
            // }));

            // this.legend.data.setAll(this.chart.series.values);

            let cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {
                behavior: "zoomY"
            }));
            cursor.lineY.set("forceHidden", true);
            cursor.lineX.set("forceHidden", true);

            this.chart.appear(1000, 100);
         
        },
        loading(){
            store.commit('loading');
        },
        loaded(){
            store.commit('loaded');
        }
    },
  	beforeDestroy() {
        if (this.root) {
            this.root.dispose();
        }
    }
}

</script>
