<template>
    <div>
        <CRow> 
            <CCol md="12">   
                <CButton :color="menu == 'challenges' ? 'info' : 'secondary'" size="sm" @click="menu != 'challenges' ? showGraph('') : '';" style="margin-right: 5px;">Concurso</CButton>
                <CButton :color="menu == 'sales' ? 'info' : 'secondary'" size="sm" @click="menu != 'sales' ? showGraph('-sales') : '';" style="margin-right: 5px;">Ventas</CButton>
            </CCol>           
        </CRow>
    </div>
</template>

<script>

export default {
    name: 'HomeMenu',
    props: {
        menu: {
            type: String,
            default() {
                return ""
            }
        },
    },
    components : {
	},
    data: function () {
		return {
            items: {},
            root: "",
        }
    },
    computed: {
        
    },
    methods: {
        showGraph: async function(graph){
            window.location = '/#/home'+graph;
        },
    },
  	mounted: async function(){        
    },
}

</script>
